

/* .actions-column {
    display: flex;
    justify-content: space-around; 
    align-items: center;
    

} */

/* .actions-column button {
    margin: 0 5px; 
    padding: -2px 10px; 
    

}
.private-container {
} */

.cancelBtn {

    min-width: 4.5rem;
    margin-Right: 27px;
    background-color: #d3d3d3;
    border: none;
    color: black
}

.primaryBtn {
    background-color: #FF6A00;
    border: none;
    color: #fff;
    min-width: 4.5rem;
}
.primaryBtn:hover {
    background-color: #be5103;
}


.btn-reset {
    background: teal; /* Example complementary color */
    color: white;
    border: none;
  }
  .btn-reset:hover {
    background: #026a6a !important;
    border: 1px solid rgb(3, 89, 89) !important;
  }