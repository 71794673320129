.table-responsive {
    max-height: 400px; /* Adjust the height as needed */
    overflow-y: auto;
}

.tbls th, .tbls td {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 150px; /* Adjust the width as needed */
}

.d-flex > * {
    /* flex: 1 1 auto; */
    /* padding: 6px; */
    /* border-radius: 6px 0px 0px 6px; */
    /* margin-right: 0.25rem; */
}

.d-flex > *:last-child {
    /* margin-right: 0; */
}
