/* Step Indicator */
.step-indicator {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  height: auto;
  background: white;
}

.step-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  text-align: center;
  cursor: pointer;
  margin-top: 8px;
  margin-left: 20px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.step-item:not(:last-child)::after {
  content: '';
  width: 100%;
  height: 2px;
  background-color: #ddd;
  position: absolute;
  top: 12px;
  left: calc(50% + 16px);
  z-index: 1;
}

.step-number {
  width: 24px;
  height: 24px;
  background-color: #ddd;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  position: relative;
}

.step-number.completed {
  background-color: #7FAB0F;
  color: #fff;
}

.step-number.completed::before {
  content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="white" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>');
  position: absolute;
  color: white;
  font-size: 20px;
}


.step-title {
  font-size: 0.9rem;
  color: #555;
}

.step-item.active .step-number {
  background-color: #ff6a00;
  color: #fff;
}

.step-item.active .step-title {
  font-weight: bold;
}

/* Form Styling */
form {
  /* max-width: 600px; */
  margin: auto;
}

button {
  /* margin-top: 1rem; */
}

button + button {
  /* margin-left: 1rem; */
}
.mt-2.triplebtn.row {
  justify-content: space-between;
}
.tiles_Size {
  padding-left: 1rem;
  padding-right: 1rem;
  border: 1px solid #0000001F !important;
}
.tiles_Size .card-body {
  flex: 1 1 auto;
  padding: 0 !important;
  color: var(--bs-card-color);
}
.mt-2.row.btn2 {
  justify-content: space-between;
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  /* margin-right: calc(0.5 * var(--bs-gutter-x)) !important;
  margin-left: calc(0.5 * var(--bs-gutter-x)) !important; */
}

.react-select__control {
  border: none;
  box-shadow: none;
  padding-left: 0;
  padding-right: 0;
}

.react-select__value-container {
  padding-left: 0;
  padding-right: 0;
}
.flex {
  display: flex;
  justify-content: space-between;
}

.view {
  margin-top: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.info-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  margin-right: 20px;
  margin-left: 20px;
}

.info-label {
  font-weight: 500;
  width: fit-content; /* Adjust width as needed */
}

.info-value {
  flex: 1;
  text-align: right; /* Align text to the right if you want values on the right side */
}
.modalLetter .modal-content{
  max-height: 550px;
  
}

.container {
  padding-left: 20px;  
  padding-right: 20px;
}

.mssy-form-container {
  /* padding: 0 20px; 
  margin: 0 auto; */
  max-width: 1200px; 
}

.cancelBtn{
  min-width: 4.5rem; 
  margin-Right: 10px;
  background-color: #d3d3d3;
  border: none;
  color: black 
}

/* .primaryBtn {
  background-color: #FF6A00;
  border: none;
  color: #fff;
  min-width: 4.5rem;
} */

.collapseContainer {
  transition: background-color 0.3s ease;
  
}

.collapseContainer.open {
  background-color: #f0f0f0;
 margin-left: -1rem;
  margin-right: -1rem;
   
}


.collapseContainer.closed {
  background-color: #ffffff; 
  margin-left: -1rem;
  margin-right: -1rem;
}

.collapseDiv {
  cursor: pointer;
  padding: 10px;
  /* padding-left: 0rem;
  padding-right: 0rem; */
}
.open .collapseDiv {
  border-bottom: 1px solid #d9d9da;
}

.content {
  padding: 15px;
}
.profile-header-container {
  display: flex;
  align-items: center;
}

.profile-header {
  margin: 0;
  font-size: 15px; 
  flex-grow: 1;
  font-weight: 600;
}

.edit-icon {
  margin-right: 56rem; 
  cursor: pointer;
}

.btn-icons {
  border: none;
  background: transparent;
  cursor: pointer;
}
.application-btn {
  /* position: relative;
  bottom: -20px */
}