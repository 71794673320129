.application_search {
    background: white;
    margin-bottom: 10px;
    margin-right: 0px !important;
    margin-left: 0px !important;
}
.application_search input[type='search'] {
    border-radius: 4px;
    border: 1px solid #b6c2d5;
    height: 35px;
    /* width: 3em; */
    background: #f5f5fc;
}

.application_search input[type='search']:focus {
    border: none;
    outline: none;
    border: 1px solid #45852e;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
}

.application_filter {
    color: #45852e;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    border-radius: 8px;
    border: 1px solid #45852e;
    background: #fff;
}

.table {
    /* border-radius: 24px; */
    /* border: 1px solid rgba(0, 0, 0, 0.08); */
    padding: 0px;
}
.table_datarow {
    padding: 0;
}
.table_header {
    /* border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    border-bottom: 1px solid #ebebeb; */
    /* background: #446c83; */
    color: black;
}

.table_data {
    /* border-bottom: 1px solid #ebebeb;
    font-family: Karla; */
}

.table_footer {
    background: #fff;
}

.table_data .approve {
    height: 32px;
    border-radius: 16px;
    background: #f3f9f0;
    color: #238e1a;
    font-family: Karla;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    letter-spacing: -0.28px;
}

.table_data .initiate {
    height: 32px;
    border-radius: 16px;
    background: #e1f2f9;
    color: #00639a;
    font-family: Karla;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    letter-spacing: -0.28px;
}

.table_data .pending {
    height: fit-content;
    border-radius: 16px;
    background: #f9f7d7;
    color: #9a7500;
    font-family: Karla;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    letter-spacing: -0.28px;
}

.pagenumber {
    padding: 3px 10px;
    border-radius: 80px;
    background: #ff6402;
    color: #fff;
}

.arrow {
    border: none;
    color: #fff;
    background-color: transparent;
}

.applicationNumber a {
    text-decoration: none;
}

.table-arrow {
    color: #238e1a;
}
.last_column {
    /* border-radius: 0px 6px 6px 0px; */
}
.first_column {
    /* border-radius: 6px 0px 0px 6px; */
    padding-left: 6px;
}
.actionTable_table_data {
    flex: 1 1 auto;
    padding-top: 10px;
    padding-bottom: 10px;
    max-width: 18rem;
    width: auto;
    text-align: justify;
}
.row_select {
    width: 3%;
}