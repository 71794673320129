.custom-dropdown {
    background-color: #dcefdc; 
    color: black; 
    border: 3px solid #dcefdc; 
  }
  
  .custom-dropdown .dropdown-toggle {
    background-color: #dcefdc !important; 
    color: black !important; 
    border: 3px solid #dcefdc; 
  }
  
  .custom-dropdown .dropdown-toggle:hover,
  .custom-dropdown .dropdown-toggle:focus {
    background-color: #dcefdc !important; 
  }
  .btn-custom-green {
    background-color: #82b74b; 
    border-color: #82b74b;
    color: #fff; 
}

.btn-custom-green:hover {
    background-color: #82b74b; 
    border-color: #82b74b;
    color: #fff; 
}

.table-responsive table td {
  background: rgb(236, 236, 249);
    font-weight: normal;
}

.table-responsive .lastRow td {
  font-weight: bold;
  font-style: italic;
}