.schemename-cls {
  text-transform: uppercase;
  color: #ffcd00;
  font-weight: 600;
  line-height: 20px;
  font-size: 22px;
  margin-bottom: 24px;
}
.banner-txt {
  font-size: 50px;
  font-weight: 700;
  color: #ffffff;
  line-height: 60px;
}
.title-decore1 {
  display: block;
  margin-top: 8px;
  margin-bottom: -8px;
  width: 110%;
  height: 10px;
}
.check-eligibility {
  background: #ff6402;
  padding: 18px;
  color: white;
  border-radius: 50px;
  /* box-shadow: 0px 0px 1px 1px #C64F04; */
  box-shadow: 0px 2px 5px 0px #c64f04;
}
.apply-scooterloan {
  background: white;
  padding: 18px;
  color: #ff6402;
  border-radius: 50px;
  /* box-shadow: 0px 0px 1px 1px #A0A0A0; */
  box-shadow: 0px 2px 5px 0px #a0a0a0;
  margin-left: 20px;
}
.ButtonIconScooter-span {
  background: white;
  /* padding: 10px; */
  border-radius: 50%;
  margin-left: -12px;
  margin-right: 8px;
  height: 45px;
  width: 45px;
  display: inline-block;
  text-align: center;
  padding-top: 10px;
}
.applyloanicon-span {
  background: #ff6402;
  /* padding: 10px; */
  border-radius: 50%;
  margin-left: -12px;
  margin-right: 8px;
  height: 45px;
  width: 45px;
  display: inline-block;
  text-align: center;
  padding-top: 10px;
}
.img-cls1 {
  border-radius: 10px;
  background: #ff6402;
  color: #ffffff;
  text-align: center;
  height: 11rem;
}
.blank-img {
  width: 70px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  margin-top: 16px;
}
.placeholder-txt {
  line-height: 30px;
  padding-top: 16px;
}
.placeholder-name {
  font-size: 17px;
  font-weight: bold;
}
.faded {
  position: relative;
  display: inline-block;
  color: white;
  text-align: end;
}
/* .faded.faded-left:after {
    box-shadow: inset 40px 0 40px -16px;
}
.faded:after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-collapse: separate;
} */
.scooteryojana-img {
  width: 80%;
}
.features-card {
  text-align: center;
  padding: 45px;
  line-height: 25px;
  cursor: pointer;
}
.features-card:hover {
  border-radius: 36px;
  box-shadow: 2px 2px 11px 0px;
}
.features-card-txt-h {
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
}

/* Portal Analytic Page CSS - START */
.portal-analytic-main .analytic-header {
  text-align: center;
  font-size: 28px;
  line-height: 35px;
  margin-bottom: 25px;
  font-weight: 600;
}
.portal-analytic-main .nav-tabs {
  border-bottom: none !important;
}
.portal-analytic-main .nav-link {
  color: black !important;
}
.portal-analytic-main .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #ff6402 !important;
  background-color: unset !important;
  border-color: #f5f5fc !important;
  border-bottom: 2px solid !important;
  font-weight: 700 !important;
}
.portal-analytic-main .nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #f5f5fc !important;
  color: #ff6402 !important;
  border-bottom: 2px solid !important;
}
.protal-chart {
  background: white;
  padding: 15px;
  box-sizing: border-box;
  border: 1px;
  border-style: solid;
  border-color: #ced4da;
}
/* Portal Analytic Page CSS - END */
/* News and Social Feeds - START */
.newsfeed-cls {
  margin: 20px 0 20px 0;
}
.newsfeed-icon-cls {
  height: 60px;
  width: 60px;
}
.newsfeed-text-cls {
  font-size: 25px;
  font-weight: 600;
  margin-left: 10px;
}
.new-btn-cls {
  background: #fc3434;
  color: white;
  padding: 5px;
  border-radius: 8px;
  text-align: center;
}
/* News and Social Feeds - END  */
/* Feedback.tsx - START */
.feedback-txt {
  font-size: 40px;
  line-height: 60px;
  font-weight: 700;
}

.feedback-carousel-main .carousel-control-prev-icon {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.feedback-carousel-main .carousel-control-next-icon {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.feedback-carousel-main .carousel-indicators [data-bs-target] {
  background-color: #ff6402;
}
.feedback-carousel-main .carousel-control-prev-icon,
.feedback-carousel-main .carousel-control-next-icon {
  height: 100px;
  /* width: 100px; */
  /* outline: black;
  background-size: 100%, 100%;
  border-radius: 50%;
  border: 1px solid black;
  background-image: none; */
}

.feedback-carousel-main .carousel-control-next-icon:after {
  content: ">";
  font-size: 55px;
  color: #ff6402;
}

.feedback-carousel-main .carousel-control-prev-icon:after {
  content: "<";
  font-size: 55px;
  color: #ff6402;
}
.feedback-profilepic {
  width: 73%;
  margin-right: 10px;
}
.carousal-pic {
  /* border-radius: 50%;
    background: #DEDEDE;
    margin-right: 10px; */
  width: -webkit-fill-available;
  height: -webkit-fill-available;
}
.feedback-name {
  font-size: 18px;
  font-weight: 600;
  color: #ff6402;
}
.feedback-address {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #5e6282;
}
.feedback-carousel {
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  box-shadow: 0px 3px 3px 0px #00000040;
  padding: 20px;
}
.feedback-txt1 {
  font-style: italic;
  font-weight: 600;
}
/* Feedback.tsx - END */

.banner-cls {
  /* background: linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ),url("../../assets/images/banner.jpg"); */
  /* opacity: .5; */
  /* background: linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ), */
  background-image: linear-gradient(to right, rgba(119, 120, 122, 0.85) 0 100%),
    url("../../assets/images/scootrYojana2.jpeg");
  /* background-size: cover; */
  width: auto;
}
.emoji {
  transition: transform 0.5s ease-in-out;
  font-weight: 600;
  font-size: 24px;
}
.happy-count {
  height: 137px;
  border: 1px solid #ced4da;
  margin-top: 20px;
  background: white;
  text-align: center;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
