/* .row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: 0 !important;
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
} */
/* .card {
    border: none;
  }
  
  .card-header {
    font-weight: bold;
    background-color: white;
  }
  
  .status-tracking-row {
    position: relative;
  }
  
  .status-tracking-step {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
  }
  
  .status-tracking-circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #28a745;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .status-tracking-line {
    position: absolute;
    width: 4px;
    background-color: #28a745;
    left: 23px;
    z-index: 0;
  }
   */
   /* .status-tracking-row {
    position: relative;
  }
  
  .status-tracking-step {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
  }
  
  .status-tracking-icon {
    font-size: 24px;
    color: #28a745;
  }
  
  .status-tracking-line {
    position: absolute;
    width: 4px;
    background-color: #28a745;
    left: 30px;
    z-index: 0;
  }
  
  .status-tracking-details {
    margin-left: 20px;
  }
  
  .status-tracking-details strong {
    font-size: 16px;
    color: #000;
  }
  
  .status-tracking-details div {
    color: #555;
  }
   */
   /* .status-tracking-step {
    display: flex;
    align-items: center;
    margin-bottom: 40px; 
    position: relative;
  }
  
  .status-tracking-icon {
    font-size: 40px; 
    color: #28a745; 
    
  }
  
  .status-tracking-line {
    position: absolute;
    width: 4px;
    height: 100%;
    background-color: #28a745;
    left: 17px;
    top: 75px;
    z-index: 0;
}
  
.status-tracking-details {
    margin-left: 40px;
    margin-top: 25px;
}
  
  .status-tracking-details strong {
    font-size: 16px;
    color: #000; 
  }
  
  .status-tracking-details div {
    color: #555; /* Grey color for secondary text 
  }
  
  @media (max-width: 768px) {
    /* For smaller screens, adjust spacing 
    .status-tracking-step {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .status-tracking-line {
      left: 12px;
      height: 50px; /* Reduce the line height on smaller screens 
    }
  
    .status-tracking-icon {
      margin-bottom: 10px;
    }
  }
   */

   /* Ensure the icon and line are aligned correctly */
.status-tracking-step {
    display: flex;
    position: relative;
    align-items: flex-start;
  }
  
  /* Icon styling */
  .status-tracking-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    background-color: #28a745; /* Change the color as needed */
    border-radius: 50%;
    color: white;
    font-size: 20px;
    z-index: 1;
  }
  
  /* Line styling */
  .status-tracking-line {
    position: absolute;
    left: 0.8rem; /* Center line with respect to the icon */
    top: 1.5rem;
    width: 4px;
    height: 100%;
    background-color: #28a745; /* Change the color as needed */
    z-index: 0;
  }
  
  /* Step details styling */
  .status-tracking-details {
    margin-left: 20px; /* Space between the icon and the text */
  }

  .status-tracking-details label{
    font-weight: 550;
    max-width: -webkit-fill-available;
  }

  .status-tracking-details div{
    font-size: 14px;
  }