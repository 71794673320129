.login {
  width: 60%;
  position: absolute;
  left: 22%;
}
.text-left.col {
  flex: 0 0;
}
.text-right.col {
  flex: 0 0;
}
div#btn {
  justify-content: space-between;
}
.text-center a {
  color: #007bff;
  text-decoration: none;
}

.text-center a:hover {
  text-decoration: underline;
}
h2 {
  font-size: 24px;
  margin-bottom: 20px;
}
div#btn mb-3 {
  justify-content: space-between;
}
.button-auto-width {
  width: auto; /* Auto width to fit the text */
  display: inline-block; /* Ensures the button size fits its content */
  white-space: nowrap; /* Prevents text from wrapping */
}
.f-14 {
  font-size: 14px;
}

.log-scooter-image {
  width: 100%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
