.footer {
  background-image: url("../../assets/images/footer.jpg");
  background-color: #cccccc;
  height: 300px;
  background-repeat: round;
}
.footer-links {
  font-family: Poppins;
  font-size: 21px;
  font-weight: 700;
  line-height: 26.15px;
  text-align: left;
  color: #ffffff;
  margin-bottom: 10px;
  text-align: center;
}
.footer-important-links {
  padding: 0;
  margin: 0;
}
.footer-important-links p {
  margin: 0;
}
.footer-important-links li {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: #ffffff;
}
.contact-us {
  font-size: 40px;
  border: 2px solid;
  border-radius: 5rem;
  color: #ffffff;
  padding: 2px;
}
.insta-icon {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 3rem;
  background: radial-gradient(
    circle at 33% 100%,
    #fed373 4%,
    #f15245 30%,
    #d92e7f 62%,
    #9b36b7 85%,
    #515ecf
  );
}
.insta-icon:after,
.insta-icon:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 25px;
  height: 25px;
  border: 2px solid #fff;
  transform: translate(-50%, -50%);
  content: "";
}
.insta-icon:before {
  border-radius: 20%;
}
.insta-icon:after {
  width: 11px;
  height: 11px;
  border-radius: 50%;
}
.copyright-cls {
  /* position: fixed; */
  color: white;
  margin-top: 5rem;
  font-size: 1.1rem;
  font-weight: bold;
  
  margin-left: 26%
}
.website-policy-page li {
  list-style: auto;
}
.website-policy-page p {
  font-size: 14px;
}
.faq-page li {
  padding: 10px;
  font-size: 14px;
}
.faq-page li .faq-q {
  font-weight: 600;
}
