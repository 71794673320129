/* body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 20px;
} */

.certificate-container {
    border: 1px solid #000;
    padding: 55px;
    padding-top:0;
    padding-bottom: 0;
    max-width: 800px;
    margin: 50px auto;
    background-color: #f9f9f9;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.certificate-title {
    background-color: #8ef3a6;
    color: #2a2929;
    padding: 0,10px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin: 20px 0;
}

.certificate-content {
    text-align: justify;
}

.signature {
    display:flex;
    align-items: self-start;
    justify-content: space-between;
    flex-direction: row;
    padding-bottom: 25px;
    
}

.signature p {
    margin-bottom: 0;
}

.paragraph-spacing {
    margin-bottom: 50px; /* Adjust this value as needed */
}