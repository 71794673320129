.image-scroll-container {
    width: 100%;
    overflow: hidden; /* Hides the overflowing content */
  }
  
  .image-scroll-wrapper {
    display: flex; /* Ensures images are in a row */
    white-space: nowrap; /* Prevents images from wrapping to the next line */
    animation: scroll 20s linear infinite; 
  }
  
  .image-scroll-wrapper img {
    max-width: 100%; /* Ensures images resize proportionally */
    height: auto;
    margin-right: 10px; /* Adjust spacing between images */
    /* box-shadow: 0px 0px 4px 0px #000000;
    border-radius: 10px;
    cursor: pointer; */
  }
  .image-scroll-wrapper:hover {
    animation-play-state: paused;
  }
  .img-scroll {
    box-shadow: 0px 0px 4px 0px #000000;
    border-radius: 10px;
    cursor: pointer;
    display: block;
  }
  .janasunani-cls{
    color: black;
    text-decoration: underline #b5bdc1;
    font-style: italic;
    font-size: 18px;
    font-weight: 600;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0); /* Start position */
    }
    100% {
      transform: translateX(-100%); /* Scroll to the left (negative direction) */
    }
  }
  