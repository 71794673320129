/* MenuBar.css */

.menu-bar {
    background-color: #2f5d62;
    height: 35px;
    font-size: 14px;
}
.menu-bar .nav-link,
.menu-bar .navbar-brand,
.menu-bar .nav-dropdown {
    color: white !important;
}

.menu-bar .nav-link:hover,
.menu-bar .nav-dropdown:hover {
    color: #ffc107 !important; /* Adjust the hover color as needed */
}

.menu-bar .dropdown-menu {
    background-color: #2f5d62; /* Adjust the dropdown background color as needed */
    border: none;
    margin: 0px 0px !important;
}

.menu-bar .dropdown-item {
    color: white !important;
    font-size: 13px;
}

.menu-bar .dropdown-item:hover {
    background-color: #ffc107 !important; /* Adjust the hover background color as needed */
    color: #2f5d62 !important; /* Adjust the hover text color as needed */
}

/* Additional CSS for nested dropdowns */
.menu-bar .dropdown-menu .dropdown-menu {
    position: absolute;
    left: 100%;
    top: 0;
    margin-top: -1px;
    /* display: none; */
    background-color: #2f5d62; /* Adjust the background color of nested dropdown */
    border: none;
}

.menu-bar .dropdown-menu .dropdown-item:focus .dropdown-menu,
.menu-bar .dropdown-menu .dropdown-item:hover .dropdown-menu {
    display: block;
}
a#partnership-dropdown {
    margin-left: 8px;
    font-size: 14px;
}
a#whos-who-dropdown {
    margin-left: 8px;
    font-size: 14px;
}
