.register {
  width: 60%;
  position: absolute;
  left: 22%;
}
.reg-scooter-image {
  width: 100%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  height: 50%;
}
.reg-left-top{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;
  height: 50%;
  padding-bottom: 1.2rem;
  padding-top: 1rem;
  justify-content: space-between;
}