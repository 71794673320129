body {
  margin: 0;
  font-family: sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #e3eaee !important; */
  background-color: #f5f5fc !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
:root {
  --field-border: 1px solid #eeeeee;
  --field-border-radius: 0.5em;
  --secondary-text: #aaaaaa;
  --sidebar-color: #f1f1f1;
  --accent-color: #2962ff;
  --bg-gray: #9c9c9d;
  --bg-light-gray: #dddddd;
}
.errorMsg {
  color: red;
  font-size: 14px;
}
.form-label {
  font-weight: 500;
  font-size: smaller;
}
.vertical-line {
  border-left: 1px solid #ccc; /* Customize the style of the vertical line */
  height: 40px; /* Adjust height as needed */
  margin: 0 10px; /* Adjust margin as needed */
}
.small-text {
  font-size: 0.8rem !important; /* Adjust the font size as needed */
}
.mt-2.row.twobtn {
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
}

.form-control:focus {
  box-shadow: none !important;
  border-bottom-color: #ff6a00 !important;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  border: none !important;
  border-bottom: 1px solid #878686 !important;
  border-radius: 8px !important;
}
/* App.css */

/* .react-select-container {
  border: none;
  padding-left: 0;
  padding-right: 0;
  padding-top: 1.25rem;
}

.react-select__control {
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  box-shadow: none;
  height: calc(3.5rem + 2px);
}

.react-select__value-container {
  padding: 0.375rem 0.75rem;
}

.react-select__placeholder, .react-select__single-value {
  margin-left: 0;
  margin-right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.react-select__indicators {
  display: flex;
  align-items: center;
}

.errorMsg {
  color: red;
  font-size: 0.875rem;
  margin-top: 0.25rem;
} */
.css-1nmdiq5-menu {
  z-index: 9 !important;
}
.css-13cymwt-control {
  border: none !important;
  min-height: 57px !important;
  border-bottom: 1px solid !important;
  border-radius: 0 !important;
}
.css-1fdsijx-ValueContainer {
  max-height: 57px !important;
  border: none !important;
  border-style: none !important;
}
.css-qbdosj-Input {
  margin-top: 5px !important;
}
.css-1jqq78o-placeholder {
  margin-left: 4px !important;
  margin-top: 32px !important;
}
.btn {
  /* margin: 10px !important; */
}
.pagination-count-button {
  min-width: 50px;
  border-radius: 6px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.color-saffron {
  color: #ff6402;
}
.background-saffron {
  background: #ff6402 !important;
  border: 1px solid #ff6402 !important;
}
.background-saffron:hover {
  background: #d35100 !important;
  border: 1px solid #ff6402 !important;
}
.fw-600 {
  font-weight: 600;
}
.DRAFT-status {
  background: #dfebc9;
}
.status-column {
  padding: 6px !important;
  border-radius: 16px;
}
.row-select {
  width: 3% !important;
}
/* input[type='checkbox'] {
  accent-color: #ff6402;
} */
.form-check {
  position: relative;
}

input[type="checkbox"] {
  content: "";
  display: block;
  position: relative;
  text-align: center;
  height: 19px;
  width: 19px;
  left: -1px;
  top: 0px;
  background-color: #ff6402;
  font-family: "Montserrat";
  border-radius: 2px;
  border: 1px solid rgb(150 150 150 / 30%);
}

input[type="checkbox"]:checked:before {
  content: "";
  display: block;
  position: relative;
  text-align: center;
  height: 19px;
  width: 19px;
  left: -1px;
  top: -1px;
  background-color: #ff6402;
  font-family: "Montserrat";
  border-radius: 2px;
  border: 1px solid rgb(150 150 150 / 30%);
}
.generic-action-table input[type="checkbox"]:checked:before {
  content: "";
  display: block;
  position: relative;
  text-align: center;
  height: 19px;
  width: 19px;
  left: 0px;
  top: 0px;
  background-color: #ff6402;
  font-family: "Montserrat";
  border-radius: 2px;
  border: 1px solid rgb(150 150 150 / 30%);
}

input[type="checkbox"]:checked::after {
  content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="white" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>');
  display: block;
  position: relative;
  /* left: 3px; */
  top: -20px;
}
.form-check-input:focus {
  border-color: #ffb485 !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(249 116 32 / 24%) !important;
}

.column-text-start {
  font-weight: 600;
  font-size: 15px;
  min-width: fit-content;
  padding: 0.5rem;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #ffffff !important;
  background-color: #ff6402 !important;
}

.dropdown-item:hover  .ms-text{
  color: #ffffff !important;
  background-color: #ff6402 !important;
}

.items-button {
  border: 1px solid;
  margin-left: 10px;
  border-radius: 6px;
  background: white;
}
.items-button:hover {
  background: #ff6402;
  color: white;
}
.drop-down-panel-li {
  border-bottom: 1px solid rgb(204, 204, 204);
  border-radius: 0px;
}
.Export_button {
  border: none;
  border-radius: 6px;
  height: 100%;
  color: white;
}
.lh-sm-2 {
  line-height: 1.35 !important;
}
.pt-6 {
  padding-top: 6rem;
}
.dropdown-item {
  border-bottom: 1px solid #ebe7e7 !important;
}
.dropdown-menu.show {
  padding: 0 !important;
}
.modal-header {
  background: #ff6a00;
  color: #ffffff;
}
li {
  list-style: none;
}
.generic-btn-submit {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  background: #ff751b;
  color: #ffffff;
  border: none;
  font-size: 20px;
}
.generic-btn-submit:hover {
  background: #f66302;
}
.generic-btn-cancel {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  font-size: 20px;
  background: #eeeeee;
  border: none;
  margin-top: 10px;
}
.generic-btn-cancel:hover {
  background: #bfbfbf;
}
.font-s-18 {
  font-size: 18px;
}
.reg-text {
  font-size: 30px;
  font-weight: bold;
}
.btn:disabled{
  background-color: #ff6a00 !important;
}
.reg-left-panel {
  color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.left-panel-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;
  padding-top: 1rem;
  padding-bottom: 1.2rem;
}
.font-s-14 {
  font-size: 14px;
}
.generic-action-table .d-flex > * {
  flex: 1 1 auto;
}

/* .cancelBtn{ */
/* background-color: #d3d3d3;
  border: none;
  color: black;
  min-width: 4.5rem; */

/* } */

.collapseDiv {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  margin-bottom: 1rem;
  cursor: pointer;
}

.collapseDiv title {
  color: #000;
  font-size: 1.125;
  font-weight: bold;
}

.open-collapse {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}
.dynamic-header {
  padding-top: 20px;
  padding-bottom: 20px;
}
.css-ategrq-control {
  border-bottom: 1px solid #878686 !important;
  border-radius: 8px !important;
}
.css-1j7pxug-control .css-1fdsijx-ValueContainer {
  padding: 1rem 0.625rem !important;
  position: absolute;
  top: 2px;
}
.css-1j7pxug-control {
  border-radius: 8px !important;
  border-bottom: 1px solid #878686 !important;
  background-color: var(--bs-secondary-bg) !important;
}
.css-olqui2-singleValue {
  color: hsl(210deg 10.81% 14.51%) !important;
}

input[type="radio"]:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -24px;
  left: -1px;
  position: relative;
  background-color: #f0f0f0;
  content: "";
  display: inline-block;
  visibility: visible;
  /* border: 2px solid white; */
}

input[type="radio"]:checked:after {
  left: -0.5px;
  background-color: #ff6402;
  border: 2px solid white;
}
input[type="radio"]:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 3px;
  /* margin: 0 5px 0 0; */
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid #ff6402;
  background-color: transparent;
  left: -3px;
}
.form-floating > label {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}
.generic-action-table tbody,
td,
tfoot,
th,
thead,
tr {
  border-bottom: 1px solid #ffffff !important;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #848282;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #848282;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.ft-b {
  font-weight: 600;
}
.ft-14 {
  font-size: 14px;
}
