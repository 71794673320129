
.parentDiv >label{
top:-0.7rem !important;
opacity: 0.8;
font-size: 14px;
}
.floating {
  position: relative;
}

.css-1jqq78o-placeholder {
  margin-top: 1rem !important;
  margin-left: 5px !important;
}

.css-1dimb5e-singleValue {
  margin-top: 1rem;
  margin-left: 5px !important;
}
