.previewContainer {
  display: flex;
  flex-direction: column;
  border-left: 4px solid #ff6402;
  margin-bottom: 1.5rem;
  padding-left: 5px;
}
.title {
  font-weight: 550;
  font-size: 0.85rem;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}
.value {
  color: "light-grey";
}
