.mobile {
    min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: green;
    
}
.mobile .card {
    max-width: 300px;
    width: 100%;
    padding: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.text-left.col {
    flex: 0 0;
}
.text-right.col {
    flex: 0 0;
}
div#btn {
    justify-content: space-between;
}
.text-center a {
    color: #ff6402;
    text-decoration: none;
  }
  
  .text-center a:hover {
    text-decoration: underline;
  }
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }