/* components/DynamicHeader.css */
/* .dynamic-header {
    display: flex;
    align-items: center;
    padding: 10px;
    margin-left: 11px;
    margin-right: 10px;
}

.header-content {
    display: flex;
    align-items: center;
}

.back-button {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    margin-right: 10px;
    display: contents;
}


.fa-solid, .fas {
    font-weight: 900;
    margin-top: 5px !important;
    font-size: x-large !important;
} */
.route-name {
    font-size: 18px;
    font-weight: bold;
    margin-top: 2px;
    margin-left: 8px;
}