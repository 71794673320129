/* src/components/CustomCaptcha.css */
.custom-captcha {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
  }
  
  .captcha-question {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .captcha-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 5px;
  }
  
  .captcha-input.is-invalid {
    border-color: #dc3545;
  }
  
  .captcha-error {
    color: #dc3545;
  }
  