.floating-label-container {
    position: relative;
    margin-bottom: 1.5rem;
  }
  
  .floating-label-container .form-control {
    height: auto;
    padding: 1.25rem 0.75rem 0.25rem;
    box-sizing: border-box;
  }
  
  .floating-label-container .form-control:focus ~ .form-label,
  .floating-label-container .form-control:not(:placeholder-shown) ~ .form-label {
    top: -0.75rem;
    left: 0.75rem;
    font-size: 0.75rem;
    opacity: 1;
  }
  
  .floating-label-container .form-label {
    position: absolute;
    top: 1.25rem;
    left: 0.75rem;
    pointer-events: none;
    transition: 0.2s ease all;
    opacity: 0.5;
  }
  
  .errorMsg {
    color: red;
    font-size: 0.875rem;
    margin-top: 0.25rem;
  }
  
  .floating-label-container .form-control.error {
    border-color: red;
  }
  