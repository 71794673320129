.profile-photo-container {
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    width: 150px; /* Adjust the size as needed */
    height: 150px; /* Adjust the size as needed */
    margin: 0 auto; /* Center horizontally */
}

.profile-photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profile-container .placeholder-photo {
  width: 9rem;
  height: 9rem;
  border-radius: 50%;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #888;
  font-size: 14px;
  text-align: center;
}



.changeBtn {
    width: 12rem;
    height: 35px;
    border-radius: 8px;
    gap: 8px;
    background: #E0E0E0;
    color: black;
}


/* ProfileInfo.css */
.profile-container {
    margin-top: 2rem;
  }
  
  
  .edit-profile-btn {
    background-color: orange;
    border-radius: 20px;
    color: white;
    border: none;
    padding: 10px 20px;
  }
  
  .profile-photo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }
  
  .profile-links {
    display: flex;
    margin-top: 10px;
  }
  
  .profile-links .remove-photo {
    color: red;
    display: flex;
    align-items: center;
    width: fit-content;
    margin-top: 5px;
  }
  
  .profile-links .remove-photo svg {
    margin-right: 5px;
  }
  