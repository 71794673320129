img.mr-2.rounded-circle {
    margin-top: 5px;
    background: white;
}
.dropdown-menu.show {
    margin: 0px -12px;
    top: 50px;
}
.private-navbar .nav-link {
    display: block;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    color: black;
    text-decoration: none;
    background: 0 0;
    border: 0;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
    /* color: white !important; */
}
.private-navbar {
    position: fixed;
    width: 100%;
    z-index: 999;
    box-shadow: 0px 1px 4px -2px;
    padding: 5px;
    background: #FAFAFA;
}
.profile-nav-img {
    border: 2px solid #d2d2d6;
    border-radius: 50%;
}
.basic-navbar-nav {
    font-size: 18px;
}
.ms-text{
  color: #ff6a00;
}